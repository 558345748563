<template>
    <modal ref="modalRechazarAnuncio" titulo="Rechazar anuncio" adicional="Rechazar" tamano="modal-md" @adicional="guardarValores">
        <div class="container">
            <ValidationObserver tag="div" ref="form">
                <div class="row justify-content-center mx-0 py-1 f-15">
                    <p>Al parecer el anuncio no cumple con algunos aspectos para ser aprobado, hazle saber esto al residente.</p>
                </div>
                <div class="row justify-content-center mx-0 py-2">
                    <ValidationProvider tag="div" class="w-100" v-slot={errors} rules="required" name="comentario"> 
                        <p class="f-12 pl-2 label-inputs">Comentario</p>
                        <el-input placeholder="Déjale un comentario al residente" v-model="inputComentario" type="textarea"  :autosize="{ minRows: 4, maxRows: 4}" />
                        <p class="text-danger f-10">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>
            </ValidationObserver>
        </div>
    </modal>
</template>
<script>
export default {
    data(){
        return{
            inputComentario:'',
        }
    },
    methods:{
        toggle(){
            this.$refs.modalRechazarAnuncio.toggle()
        },
        async guardarValores(){
            
            let validate = await this.$refs.form.validate()
            if(!validate) return this.notificacion('','Por favor escriba un comentario')

            this.$emit('rechazar',this.inputComentario)
            this.$refs.modalRechazarAnuncio.toggle()
        }
    }
}
</script>
<style lang="scss" scoped>

</style>